<!-- 校园安全 > 宿舍考勤 > 考勤排班 -->
<template>
    <div class="content-wrapper">
        <div class="form-wrapp">
            <div style="display: flex; align-items: center;flex:1;">
                <el-date-picker v-model="groupSearchTime" type="daterange" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :clearable="false"
                    @change="handleDateChange" style="width: 320px;" />
                <el-button type="warning" style="margin-left: 10px" @click="handleRescheduling"
                    v-hasPermi="['schedule:reset']">
                    <span class="spanSty">
                        <img :src="reschedulingUrl" alt="" />
                        重新排班
                    </span>
                </el-button>
                <el-button type="primary" style="margin-left: 10px" @click="handleClockinginSetting()" v-hasPermi="['schedule:checkTime']">
                    <span class="spanSty">
                        <img :src="checkUrl" alt="" />
                        考勤设置
                    </span>

                </el-button>
                <el-button style="margin-left:auto;" @click="routerType">异常推送设置</el-button>
                <el-button v-hasPermi="['schedule:export']" @click="handleExport" :loading="exportLoading">
                    <span class="spanSty">
                        <!-- <img :src="exportUrl" alt="" /> -->
                        <span>导出</span>
                    </span>
                </el-button>
            </div>
        </div>
        <div style="width:90vw;" v-loading="loadingTable">
            <div class="table-wrap">
                <table class="calendar-table">
                    <thead>
                        <tr>
                            <th>
                                <div style="display: flex; align-items: center">
                                    <el-cascader :key="keyValue" v-model="cascaderValue" ref="cascader"
                                        :options="cascaderOptions" :props="cascaderProps" collapse-tags filterable :placeholder="type === 1 ? '请选择教师' : '请选择宿舍'
                                            " @remove-tag="handleVisibleChange(false)" @visible-change="handleVisibleChange" />
                                    <img :src="changeUrl" alt="" @click="handleChangeType" class="change-img" :style="{
                                        cursor: disabledBtn
                                            ? 'not-allowed'
                                            : 'pointer',
                                    }" />
                                </div>
                            </th>
                            <th v-for="(item, index) in curDays" :key="index">
                                <div class="header-item">
                                    <div>{{ item.week }}</div>
                                    <div>
                                        {{
                                            Number(
                                                item.date
                                                    .split("-")
                                                    .slice(2)
                                                    .join("-"),
                                            )
                                        }}
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, i) in list" :key="i">
                            <td height="76px" style="min-width: 280px">
                                {{ item.name }}
                            </td>
                            <td v-for="(day, index) in curDays" :key="index" @click.prevent="handleItemClick(day, i, index)"
                                @mouseover="handleItemMove(day, i, index)" :class="[
                                    {
                                        'is-range': item.data[day.date]
                                            ? item.data[day.date].isRange
                                            : '',
                                    },
                                ]" :style="{
        background:
            item.data[day.date] &&
                item.data[day.date].data
                ? colorList[i % 10]
                : '',
        'border-color':
            item.data[day.date] &&
                item.data[day.date].data
                ? colorList[i % 10]
                : '',
    }">
                                <el-popover v-if="item.data[day.date] &&
                                    item.data[day.date].data
                                    " placement="bottom" trigger="hover" popper-class="popover-wrapper-hxh"
                                    :open-delay="200">
                                    <div v-for="(item, index) in item.data[day.date]
                                        .data" :key="index" class="room-name popover-room-name"
                                        :style="{ background: colorList[i % 10] }">
                                        <div
                                            style="border-left: 2px solid #999999;padding: 0 3px;line-height: 12px;margin-left: 10px;">
                                            {{ item.shiftName }}
                                        </div>
                                        <div v-for="(subItem, subIndex) in item.roList" :key="subIndex"
                                            style="text-align: center;">{{ subItem }}</div>
                                    </div>
                                    <div slot="reference" class="popover-slot">
                                        <div v-for="(item, index) in getFirstThreeNodes(item.data[day.date].data)" :key="index" class="room-name">
                                            <div class="table-room-name">
                                                <span class="room-name-text">{{ item }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </el-popover>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
       
        <!-- 再次确认 -->
        <dialog-wrapper :dialogObj="dialogObj" @handleClose="handleClose">
            <el-form v-if="moveIndex.columnIndex.length === 2" label-width="auto" class="common-form">
                <el-form-item label-width="0">
                    <span>{{ curDays[moveIndex.columnIndex[0]].date }}~</span>
                    <span>{{ curDays[moveIndex.columnIndex[1]].date }}，</span>
                    <span>共{{
                        moveIndex.columnIndex[1] -
                        moveIndex.columnIndex[0] +
                        1
                    }}天</span>
                </el-form-item>
                <div>
                    <div style="font-size: 14px;font-family: Microsoft YaHei;font-weight: bold;color: #333333;
                    margin: 10px 0;">{{ this.clockinginRuleForm.shiftName }}</div>
                    <el-form-item :label="type === 1 ? '宿舍' : '教师'" style="margin-left: 30px;">
                        <el-cascader v-model="dialogCascaderValue" ref="dialogCascader" :options="dialogCascaderOptions"
                            :props="cascaderProps" filterable collapse-tags class="input-width-small" />
                    </el-form-item>
                </div>
                <div v-if="this.clockinginRuleForm.shiftName1">
                    <div
                        style="font-size: 14px;font-family: Microsoft YaHei;font-weight: bold;color: #333333;margin: 10px 0;">
                        {{ this.clockinginRuleForm.shiftName1 }}</div>
                    <el-form-item :label="type === 1 ? '宿舍' : '教师'" style="margin-left: 30px;">
                        <el-cascader v-model="dialogCascaderValue1" ref="dialogCascader1" :options="dialogCascaderOptions"
                            :props="cascaderProps" filterable collapse-tags  class="input-width-small" />
                    </el-form-item>
                </div>

            </el-form>
            <div style="text-align: right">
                <xk-button type="enquiry" @click="handleClose">取消</xk-button>
                <xk-button type="primary" @click="handleSubmit">确定</xk-button>
            </div>
        </dialog-wrapper>
        <!-- 考勤设置 -->
        <dialog-wrapper :dialogObj="clockinginDialogObj" @handleClose="handleClockinginClose" class="common-form">
            <el-form ref="ruleForm" :model="clockinginRuleForm" :rules="clockinginRules" class="attendanceSetting_form" >
                <div style="display: flex;  align-items: center;  margin: 4px 0 34px 0;">
                    <div class="attendanceSetting">
                        <div class="line"></div>
                        <span class="title">查寝班次</span>
                    </div>
                    <el-form-item prop="classesType" label="">
                        <el-radio-group v-model="clockinginRuleForm.classesType">
                            <el-radio :label="1">一天一寝</el-radio>
                            <el-radio :label="2">一天两寝</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>
                <div style="margin-bottom: 32px;">
                    <div class="attendanceSetting" style="margin-bottom: 10px;">
                        <div class="line"></div>
                        <span class="title">核对时间</span>
                        <span class="tip">班主任核对</span>
                    </div>
                    <el-form-item prop="collateTime" label="">
                        <el-time-picker is-range v-model="clockinginRuleForm.collateTime" range-separator="至"
                            start-placeholder="开始时间" end-placeholder="结束时间" value-format="HH:mm" format="HH:mm"
                            style="width: 530px;" >
                        </el-time-picker>
                    </el-form-item>
                    <div class="tip" style="margin-top: 16px;">开始时间前不可进行核对，结束时间后还可进行核对，会标记异常。</div>
                </div>
                <div style="margin-bottom: 32px;">
                    <div class="attendanceSetting" style="margin-bottom: 10px;">
                        <div class="line"></div>
                        <span class="title">检查时间</span>
                        <span class="tip">宿管检查</span>
                    </div>
                    <div style="display: flex;">
                        <el-form-item prop="shiftName">
                            <el-input v-model="clockinginRuleForm.shiftName" placeholder="输入班次名称" maxlength="5"
                                style="width: 140px;"></el-input>
                        </el-form-item>
                        <el-form-item prop="inspectTime" label="">
                            <el-time-picker is-range v-model="clockinginRuleForm.inspectTime" range-separator="至"
                                start-placeholder="开始时间" end-placeholder="结束时间" value-format="HH:mm" format="HH:mm"
                                style="width: 380px;">
                            </el-time-picker>
                        </el-form-item>
                    </div>
                    <div style="display: flex;margin-top: 16px;" v-if="clockinginRuleForm.classesType === 2">
                        <el-form-item prop="shiftName1">
                            <el-input v-model="clockinginRuleForm.shiftName1" placeholder="输入班次名称" maxlength="5"
                                style="width: 140px;"></el-input>
                        </el-form-item>
                        <el-form-item prop="inspectTime1" label="">
                            <el-time-picker is-range v-model="clockinginRuleForm.inspectTime1" range-separator="至"
                                start-placeholder="开始时间" end-placeholder="结束时间" value-format="HH:mm" format="HH:mm"
                                style="width: 380px;">
                            </el-time-picker>
                        </el-form-item>
                    </div>
                    <div class="tip" style="margin-top: 16px;">开始时间前不可进行检查，结束时间后还可进行检查，会标记异常。</div>
                </div>

                <div style="margin-bottom: 24px;">
                    <div class="attendanceSetting" style="margin-bottom: 10px;">
                        <div class="line"></div>
                        <span class="title">查寝换班配置</span>
                    </div>
                    <el-form-item prop="isShiftChange" label="是否允许换班">
                        <el-radio-group v-model="clockinginRuleForm.isShiftChange">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="2">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="approvalFormId" label="换班流程" v-if="clockinginRuleForm.isShiftChange === 1">
                        <el-select v-model="clockinginRuleForm.approvalFormId" placeholder="请选择审批单" style="width:390px">
                            <el-option v-for="item in approvalFormList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <div v-if="clockinginRuleForm.isShiftChange === 1" style=" margin-left: 100px;margin-top: 16px;font-size: 12px;
                    font-family: Microsoft YaHei;font-weight: 400;color: #999999;">
                        无表单可选？ 请点击
                        <span style="color: #2F95F5;cursor: pointer" @click="goOaManage">OA管理</span>
                        创建审批单
                    </div>
                </div>
            </el-form>
            <div style="text-align: right">
                <el-button type="enquiry" @click="handleClockinginClose">取消</el-button>
                <el-button type="primary" @click="handleClockinginSubmit">确认</el-button>
            </div>
        </dialog-wrapper>
    </div>
</template>

<script>
import { DialogWrapper } from "common-local";
import {
    getCurWeek,
    getDaysInMonth,
    parseTime,
} from "./CampusSafetyAccessManager/accessSetting/utils";
import { CampusSafetyModel } from "@/models/CampusSafety";
import { CampusSafetyDormitoryModel } from "@/models/CampusSafetyDormitoryModel";
import { mapState } from "vuex";
import { downloadFile, formatTimeObj } from "@/libs/utils";
import RecordsList from '@/libs/pageListOptimal.js';
export default {
    components: {
        DialogWrapper,
    },
    data() {
        return {
            approvalFormList: [],
            colorList: [
                "#dcebfa",
                "#f7e5d2",
                "#d0f5e9",
                "#fce1de",
                "#f1deff",
                "#f2f29d",
                "#d5fabb",
                "#d0f2f7",
                "#ffdbea",
                "#e8e3fc",
            ],
            type: 1, // 1 教师 2 宿舍
            groupSearchTime: [],
            startTime: "",
            endTime: "",
            pickerOptions: {
                onPick: ({ maxDate, minDate }) => {
                    this.choiceDate = minDate.getTime();
                    if (maxDate) {
                        this.choiceDate = "";
                    }
                },
                disabledDate: (time) => {
                    const self = this;
                    if (self.choiceDate) {
                        const selectDate = new Date(self.choiceDate);
                        const nowYear = selectDate.getFullYear(); // 当前年
                        const nowMonth = selectDate.getMonth(); // 当前月
                        // 本月的开始时间
                        const monthStartDate = new Date(
                            nowYear,
                            nowMonth,
                            1,
                        ).getTime();
                        // 本月的结束时间
                        const monthEndDate = new Date(
                            nowYear,
                            nowMonth + 1,
                            0,
                        ).getTime();
                        // 此处以不能跨月做示范
                        return (
                            time.getTime() < monthStartDate ||
                            time.getTime() > monthEndDate
                        );
                    }
                },
            },
            curDays: [], // 当前月的日期
            list: [],
            moveIndex: {
                rowIndex: "",
                columnIndex: [],
            },
            teacherList: [],
            roomList: [],
            keyValue: 0,
            cascaderValue: [],
            teacherCascaderValue: [],
            roomCascaderValue: [],
            cascaderOptions: [],
            cascaderProps: {
                value: "id",
                label: "name",
                multiple: true,
                emitPath: false,
            },
            dialogObj: {
                title: "再次确认",
                dialogVisible: false,
                width: "auto",
            },
            dialogCascaderValue: [],
            dialogCascaderValue1: [],//第二个班次
            dialogCascaderOptions: [],
            //考勤设置
            clockinginDialogObj: {
                title: "考勤设置",
                dialogVisible: false,
                width: "auto",
            },
            clockinginRuleForm: {
                ids: [],//考勤设置id
                classesType: 1,//查寝班次:1:一寝,2:二寝
                collateTime: ["00:00","23:00"],//核对时间
                shiftName: '晚班',//班次名称
                inspectTime: ["00:00","23:00"],//检查时间
                shiftName1: '',//班次名称(二寝)
                inspectTime1: null,//检查时间(二寝)
                isShiftChange: 2,//是否切换班次:2:否,1:是
                approvalFormId: '',//审批单id
            },
            clockinginRules: {
                collateTime: [
                    {
                        required: true,
                        message: "请选择核对时间",
                        trigger: "change",
                    },
                ],
                shiftName: [
                    {
                        required: true,
                        message: "请输入班次名称",
                        trigger: "blur",
                    },
                ],
                inspectTime: [
                    {
                        required: true,
                        message: "请选择检查时间",
                        trigger: "change",
                    },
                ],
                shiftName1: [
                    {
                        required: true,
                        message: "请输入班次名称",
                        trigger: "blur",
                    },
                ],
                inspectTime1: [
                    {
                        required: true,
                        message: "请选择检查时间",
                        trigger: "change",
                    },
                ],
                isShiftChange: [
                    {
                        required: true,
                        message: "请选择是否切换班次",
                        trigger: "change",
                    },
                ],
                approvalFormId: [
                    {
                        required: true,
                        message: "请选择审批单",
                        trigger: "change",
                    },
                ],
            },
            //核对设置、核查时间
            // timeDialogObj: {
            //     title: "",
            //     dialogVisible: false,
            //     width: "auto",
            // },
            timeRuleForm: {
                id: "",
                schoolId: "",
                startTime: "",
                endTime: "",
                timeType: "",
                checkType: 1, // 添加核对方式 默认手动
            },
            timeRules: {
                startTime: [
                    {
                        required: true,
                        message: "请选择开始时间",
                        trigger: "change",
                    },
                ],
                endTime: [
                    {
                        required: true,
                        message: "请选择结束时间",
                        trigger: "change",
                    },
                ],
                checkType: [
                    {
                        required: true,
                        message: "请选择核对类型",
                        trigger: "change",
                    },
                ],
            },
            disabledBtn: false,
            flag: true,
            loadingTable:false,
            exportLoading: false
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            permissions: (state) => state.permissions,
        }),
        changeUrl() {
            return require("@/assets/images/change-calendar-type.png");
        },
        checkUrl() {
            return require("@/assets/images/check-time.png");
        },
        inspectUrl() {
            return require("@/assets/images/inspect-time.png");
        },
        reschedulingUrl() {
            return require("@/assets/images/re-scheduling.png");
        },
        exportUrl() {
            return require("@/assets/images/export-btn.png");
        },
        timeRuleFormMessage() {
            return this.timeRuleForm.checkType == 1
                ? "由班主任手动核对宿舍人员请假结果"
                : "无需手动核对，检查时系统自动默认宿舍人员全员到齐";
        },
    },
    created() {
        this.groupSearchTime = this.getFirstAndLastDay();
        this.setPageInfo();
        this.handleGetDays();
        this.getTeacherList();
        this.getRoomList();
        this.checkAuth();
        this.initClockinginData();//初始化考勤设置数据
        this.getApprovalForm();//获取审批单下拉数组
    },
    activated() {
        this.initClockinginData();
    },
    mounted() {
        this.getList();
    },
    methods: {
        savePageInfo(){
            //存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                groupSearchTime: this.groupSearchTime
            })
        },
        setPageInfo(){
            //赋值--存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            if(recordsList.get(this.$route.name)){
                this.groupSearchTime = recordsList.get(this.$route.name).groupSearchTime;
                recordsList.remove(this.$route.name)
            }
        },
        getTeacherList() {
            const campusSafetyModel = new CampusSafetyModel();
            campusSafetyModel
                .getIssueScopeByAccess({
                    schoolId: this.schoolId,
                    applyPerson: "1",
                })
                .then((res) => {
                    this.deleteChildren(res.data.data);
                    this.teacherList = res.data.data;
                    this.cascaderOptions = this.teacherList;
                });
        },
        getRoomList() {
            this._fet("/school/schoolDormitoryRoom/getAllListRemoveEmpty", {
                schoolId: this.schoolId,
            }).then((res) => {
                this.deleteChildren(res.data.data);
                this.roomList = res.data.data;
                this.dialogCascaderOptions = this.roomList;
            });
        },
        deleteChildren(data, key = "children") {
            data.forEach((item) => {
                if (item.names) {
                    item.name = item.names;
                    delete item.names;
                }
                if (item[key] && item[key].length === 0) {
                    delete item[key];
                } else if (item[key]) {
                    this.deleteChildren(item[key]);
                }
            });
        },
        handleChangeType() {
            if (!this.disabledBtn) {
                this.type = this.type === 1 ? 2 : 1;
            }
        },
        handleVisibleChange(data) {
            if (!data) {
                this.getList();
            }
            setTimeout(() => {
                this.disabledBtn = data;
            }, 0);
        },
        /**
         * @Description: 获取审批单下拉数组
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-07-10 10:11:15
         */
        getApprovalForm(){
            this._fet("/oa/schoolFormInfo/listByCondition", {
                schoolId: this.schoolId,
                formType:2,
                status:1,
            }).then((res) => {
                if(res.data.code==200){
                    this.approvalFormList=res.data.data.map(item=>{
                        return {
                            label:item.formName,
                            value:item.id,
                        }
                    })
                }
               console.log(res,'审批单下拉数组',this.approvalFormList);
            });
        },
        /**
         * @Description: 跳转OA管理
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-07-10 10:29:14
         */
        goOaManage(){
            this.clockinginDialogObj.dialogVisible=false;
            this.$router.push({
                path: "/approvalManage",
            });
        },
        getList() {
            if (!this.flag) {
                return;
            }
            this.flag = false;
            this.disabledBtn = true;
            this.$nextTick(async () => {
                let time = this.groupSearchTime || this.getFirstAndLastDay();
                let obj = {
                    schoolId: this.schoolId,
                    type: this.type,
                    startTime: time[0],
                    endTime: time[1],
                };
                this.list = [];
                this.loadingTable = true;
                if (this.type === 1) {
                    await this.getType1List(obj);
                } else {
                    await this.getType2List(obj);
                }
                this.loadingTable = false;
                this.flag = true;
                this.disabledBtn = false;
            });
        },
        async getType1List(obj) {
            const list = this.$refs.cascader
                .getCheckedNodes()
                .filter((i) => i.data.organType !== "1")
                .map((i) => i.data);
            obj.teacherIds = list.map((i) => i.id);
            if (
                this.$refs.cascader.getCheckedNodes().length !== 0 &&
                obj.teacherIds.length === 0
            ) {
                return;
            }
            await this._fet(
                "/school/schoolDormitoryScheduling/schedulingList",
                obj,
            ).then((res) => {
                if (obj.teacherIds.length > 0) {
                    list.forEach((item) => {
                        let obj = {};
                        let find = res.data.data.find((i) => i.id === item.id);
                        if (find) {
                            find.dateList.forEach((item) => {
                                obj[item.date] = {
                                    data: item.list,
                                };
                            });
                        }
                        this.list.push({
                            id: item.id,
                            name: item.name,
                            data: obj,
                        });
                    });
                } else {
                    res.data.data.forEach((item) => {
                        let obj = {};
                        item.dateList.forEach((item) => {
                            obj[item.date] = {
                                data: item.list,
                            };
                        });
                        item.data = obj;
                        delete item.dateList;
                    });
                    this.list = res.data.data;
                }
                this.list = this._.uniqBy(this.list, (item) => item.id);
                this.cascaderValue = this.list.map((i) => i.id);
            });
        },
        async getType2List(obj) {
            const ids = this.$refs.cascader
                .getCheckedNodes()
                .filter((i) => i.level === 3)
                .map((i) => i.data)
                .map((i) => i.id);
            obj.roomIds = ids;
            if (
                this.$refs.cascader.getCheckedNodes().length !== 0 &&
                ids === 0
            ) {
                return;
            }
            const list = await this._fet(
                "/school/schoolDormitoryRoom/getRoomList",
                {
                    schoolId: this.schoolId,
                    ids: ids,
                    startTime: obj.startTime,
                    endTime: obj.endTime,
                },
            );
            await this._fet(
                "/school/schoolDormitoryScheduling/schedulingList",
                obj,
            ).then((res) => {
                list.data.data.forEach((item) => {
                    let obj = {};
                    let find = res.data.data.find((i) => i.id === item.id);
                    if (find) {
                        find.dateList.forEach((item) => {
                            obj[item.date] = {
                                data: item.list,
                            };
                        });
                    }
                    this.list.push({
                        id: item.id,
                        name: item.name,
                        dormitoryId: item.dormitoryId,
                        floorId: item.floorId,
                        data: obj,
                    });
                });
                this.cascaderValue = this.list.map((i) => i.id);
            });
        },
        handleDateChange(data) {
            this.handleGetDays(data);
            this.getList();
        },
        handleGetDays(data) {
            let year = new Date().getFullYear(),
                month = new Date().getMonth(),
                start = 1,
                end = getDaysInMonth(year, month);

            if (data && data.length === 2) {
                year = data[0].split("-")[0];
                month = data[0].split("-")[1] - 1;
                start = Number(data[0].split("-")[2]);
                end = Number(data[1].split("-")[2]);
            }
            this.curDays = [];
            for (let i = start; i <= end; i++) {
                const date = parseTime(new Date(year, month, i));
                this.curDays.push({
                    date: date,
                    week: getCurWeek(date),
                });
            }
        },
        handleItemClick(day, i, index) {
            if (day.date < formatTimeObj(new Date(), "y-m-d")) {
                return this.$message.warning("历史日期不允许排班");
            }
            if (this.moveIndex.rowIndex === "") {
                this.moveIndex.rowIndex = i;
            } else {
                if (this.moveIndex.rowIndex !== i) {
                    return this.$message.warning("所选范围不符合规则");
                }
            }
            this.moveIndex.columnIndex.push(index);
            this.moveIndex.columnIndex.sort((a, b) => a - b);
            if (this.moveIndex.columnIndex.length === 2) {
                for (
                    let m = this.moveIndex.columnIndex[0];
                    m < this.moveIndex.columnIndex[1];
                    m++
                ) {
                    if (this.list[i].data[this.curDays[m].date]) {
                        this.list[i].data[this.curDays[m].date].isRange = true;
                    } else {
                        this.list[i].data[this.curDays[m].date] = {
                            isRange: true,
                        };
                    }
                }
                if (this.checkAuth()) {
                    this.dialogObj.dialogVisible = true;
                }
            }
            if (this.list[i].data[this.curDays[index].date]) {
                this.list[i].data[this.curDays[index].date].isRange = true;
            } else {
                this.list[i].data[this.curDays[index].date] = {
                    isRange: true,
                };
            }
            this.$forceUpdate();
        },
        checkAuth() {
            return this.permissions.find((item) => {
                return item == "schedule:edit";
            });
        },
        handleItemMove(day, i, index) {
            // if (this.moveIndex.columnIndex.length === 1 && i === this.moveIndex.rowIndex) {
            //     const columnIndex = this.moveIndex.columnIndex[0]
            //     Object.keys(this.list[i].data).forEach((key) => {
            //         if (key !== this.curDays[columnIndex].date) {
            //             this.list[i].data[key].isRange = false
            //         }
            //     })
            //     for (let m = Math.min(index, columnIndex); m <= Math.max(index, columnIndex); m++) {
            //         const key = this.curDays[m].date
            //         if (this.list[i].data[key]) {
            //             this.list[i].data[key].isRange = true
            //         } else {
            //             this.list[i].data[key] = {
            //                 isRange: true
            //             }
            //         }
            //     }
            //     this.$forceUpdate()
            // }
        },
        /**
         * @Description: 再次确认--确定
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-06-27 11:14:16
         */
        handleSubmit() {
            let schedulingDateList = [];
            for (
                let i = this.moveIndex.columnIndex[0];
                i <= this.moveIndex.columnIndex[1];
                i++
            ) {
                schedulingDateList.push(this.curDays[i].date);
            }
            let obj = {
                schoolId: this.schoolId,
                type: this.type,
                schedulingDateList: schedulingDateList,
            };
            if (this.type === 1) {//教师
                const teacherData = this.list[this.moveIndex.rowIndex];
                obj["teacherId"] = teacherData.id;
                obj["teacherName"] = teacherData.name;
                obj["dormitoryVOList"] = [];//先定义一个空数组，然后把两个数组（两寝时）放进去
                //第一个班次
                let list = this.$refs.dialogCascader
                    .getCheckedNodes()
                    .filter((i) => i.level === 3);
                list.map((item) => (
                    obj["dormitoryVOList"].push({
                    dormitoryId: item.path[0],
                    floorId: item.path[1],
                    roomId: item.path[2],
                    shiftType: this.clockinginRuleForm.shiftName
                })
                ));
                //第二个班次
                if(this.$refs.dialogCascader1){
                    let list1 = this.$refs.dialogCascader1
                    .getCheckedNodes()
                    .filter((i) => i.level === 3);
                 list1.map((item) => (
                    obj["dormitoryVOList"].push({
                    dormitoryId: item.path[0],
                    floorId: item.path[1],
                    roomId: item.path[2],
                    shiftType: this.clockinginRuleForm.shiftName1
                })
                ));
                }
            } else {//宿舍
                const roomData = this.list[this.moveIndex.rowIndex];
                obj["dormitoryId"] = roomData.dormitoryId;
                obj["floorId"] = roomData.floorId;
                obj["roomId"] = roomData.id;
                obj["dormitoryVOList"] = [];
                //第一个班次
                let list = this.$refs.dialogCascader
                    .getCheckedNodes()
                    .map((i) => i.data)
                    .filter((i) => i.organType !== "1");
                list.map((item) => (
                    obj["dormitoryVOList"].push({
                    teacherId: item.id,
                    teacherName: item.name,
                    shiftType: this.clockinginRuleForm.shiftName
                })
                ));
                //当有两个班次时，再次确认的时候，把第二个班次的数据也传给后台
                if(this.$refs.dialogCascader1){
                     let list1 = this.$refs.dialogCascader1
                    .getCheckedNodes()
                    .map((i) => i.data)
                    .filter((i) => i.organType !== "1");
                list1.map((item) => (
                    obj["dormitoryVOList"].push({
                    teacherId: item.id,
                    teacherName: item.name,
                    shiftType: this.clockinginRuleForm.shiftName1
                })
                ));
                }

            }
            this._fet("/school/schoolDormitoryScheduling/check", obj).then(
                (res) => {
                    if (
                        res.data.code === "200" &&
                        (res.data.data.sameList.length > 0 ||
                            res.data.data.unassigned.length > 0)
                    ) {
                        let msg = "";
                        if (res.data.data.sameList.length > 0) {
                            msg += `${res.data.data.sameList.toString()}宿舍已有老师检查,`;
                        }
                        if (res.data.data.unassigned.length > 0) {
                            msg += `${res.data.data.unassigned.toString()}宿舍没有分配学生,`;
                        }
                        this.$confirm(`<div style="max-height: 200px;overflow-y: auto;">${msg}是否继续安排排班?</div>`, "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                            dangerouslyUseHTMLString: true,
                        })
                            .then(() => {
                                this.handleScheduling(obj);
                            })
                            .catch(() => {
                                this.handleClose();
                            });
                    } else {
                        // 如果选择的教师或宿舍不为空，则发请求
                        if (obj["dormitoryVOList"].length > 0) {
                            this.handleScheduling(obj);
                        } else {
                            //未选择，则不发请求，直接关闭弹窗
                            this.handleClose();
                        }
                    }
                },
            );
        },
        handleScheduling(obj) {
            console.log(obj, 'scheduling接口的传值');
            this._fet("/school/schoolDormitoryScheduling/scheduling", obj).then(
                (res) => {
                    if (res.data.code === "200") {
                        this.$message.success("操作成功");
                        this.handleClose();
                        this.getList();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                },
            );
        },
        /**
         * @Description: 取消再次确认弹框
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-06-27 11:13:28
         */
        handleClose() {
            for (
                let i = this.moveIndex.columnIndex[0];
                i <= this.moveIndex.columnIndex[1];
                i++
            ) {
                this.list[this.moveIndex.rowIndex].data[
                    this.curDays[i].date
                ].isRange = false;
            }
            this.dialogObj.dialogVisible = false;
            this.dialogCascaderValue = [];
            this.dialogCascaderValue1 = [];
            this.moveIndex = {
                rowIndex: "",
                columnIndex: [],
            };
        },
        /**
         * @Description: 重新排班
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-06-27 11:12:58
         */
        handleRescheduling() {
            if (this.startTime === "" || this.endTime === "") {
                return this.$message.warning("请选择开始时间或者结束时间");
            }
            if (new Date().getTime() > new Date(this.startTime).getTime()) {
                return this.$message.warning(
                    "系统当前日期之前的排班内容不允许重新排班。",
                );
            }
            this.$confirm("确认重新排班?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this._fet(
                        "/school/schoolDormitoryScheduling/rescheduling",
                        {
                            schoolId: this.schoolId,
                            startTime: this.startTime,
                            endTime: this.endTime,
                        },
                    ).then((res) => {
                        if (res.data.code === "200") {
                            this.$message.success("重新排班成功");
                            this.getList();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    });
                })
                .catch(() => { });
        },
        /**
         * @Description: 导出
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-06-27 11:12:32
         */
        handleExport() {
            if (this.startTime === "" || this.endTime === "") {
                return this.$message.warning("请选择开始时间或者结束时间");
            }
            let form = {
                schoolId: this.schoolId,
                type: this.type,
                startTime: this.startTime,
                endTime: this.endTime,
            };
            const ids = this.list.map((i) => i.id);
            if (this.type === 1) {
                form.teacherIds = ids;
            } else {
                form.roomIds = ids;
            }
            this.exportLoading = true;
            downloadFile(
                {
                    url: "/school/schoolDormitoryScheduling/export",
                    form: form,
                    fileName: "考勤排班信息表.xlsx",
                },
                () => {
                    this.$message.success("导出成功");
                    this.exportLoading = false;
                },
                () => { },
            );
        },
        /**
         * @Description: 初始化考勤设置接口
         * @DoWhat: 查询考勤设置，回显
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-06-27 15:56:13
         */
        initClockinginData(type) {
            const campusSafetyDormitoryModel = new CampusSafetyDormitoryModel()
            campusSafetyDormitoryModel.CampusSafetyGetSetting({
                schoolId: this.schoolId,
                timeType: type || '',
            }).then(res => {
                console.log(res.data.data, '查询考勤设置，回显');
                let obj = res.data.data
                if (res.data.code === '200') {
                    this.clockinginRuleForm.classesType = Number(obj.daySleepNum)
                    if (obj.list.length > 0) {
                        obj.list.forEach((item, index) => {
                            if (item.timeType === '1') {//核对时间回显赋值
                                this.clockinginRuleForm.collateTime[0] = item.startTime
                                this.clockinginRuleForm.collateTime[1] = item.endTime
                                this.clockinginRuleForm.ids[0] = item.id
                                console.log(this.clockinginRuleForm, '附上值了吗');
                            } else if (item.timeType === '3') {//查寝换班配置回显赋值
                                this.clockinginRuleForm.isShiftChange = Number(item.isShiftChange)
                                this.clockinginRuleForm.approvalFormId = item.approvalFormId
                                this.clockinginRuleForm.ids[1] = item.id
                            }
                        })
                        //检查时间--两寝回显
                        let arr = obj.list.filter(item => item.timeType === '2')
                        console.log(arr, '返回的是两寝的检查时间嘛？');
                        // //第一组
                        if (arr.length > 0) {
                            this.clockinginRuleForm.inspectTime[0] = arr[0].startTime
                            this.clockinginRuleForm.inspectTime[1] = arr[0].endTime
                            this.clockinginRuleForm.ids[2] = arr[0].id
                            this.clockinginRuleForm.shiftName = arr[0].shiftName
                            this.clockinginRuleForm.shiftName1 =''
                            this.clockinginRuleForm.inspectTime1 = null
                            this.clockinginRuleForm.ids[3] = ''

                        }
                        // //第二组
                        if (arr.length > 1) {
                            this.clockinginRuleForm.inspectTime1 = []
                            this.clockinginRuleForm.inspectTime1[0] = arr[1].startTime || []
                            this.clockinginRuleForm.inspectTime1[1] = arr[1].endTime || ''
                            this.clockinginRuleForm.ids[3] = arr[1].id || ''
                            this.clockinginRuleForm.shiftName1 = arr[1].shiftName || ''
                        }


                        console.log(arr, '返回的是两寝的检查时间嘛？',this.clockinginRuleForm);
                    }else{
                        this.clockinginDialogObj.dialogVisible = true;
                    }

                }
                console.log(this.clockinginRuleForm, '初始化考勤设置接口处理后的数据');
            })


        },
        /**
         * @Description: 考勤设置
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-06-26 14:39:40
         */
        handleClockinginSetting() {
            this.initClockinginData()
            this.clockinginDialogObj.dialogVisible = true;
        },
        /**
         * @Description: 关闭考勤设置弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-06-26 15:12:50
         */
        handleClockinginClose() {
            this.$refs.ruleForm.clearValidate();
            this.clockinginDialogObj.dialogVisible = false;
        },
        /**
         * @Description: 考勤设置提交
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-06-27 14:31:18
         */
        handleClockinginSubmit() {
            // classesType: 1,//查寝班次:1:一寝,2:二寝
            //     collateTime:'',//核对时间
            //     shiftName:'',//班次名称
            //     inspectTime:'',//检查时间
            //     shiftName1:'',//班次名称(二寝)
            //     inspectTime1:'',//检查时间(二寝)
            //     isShiftChange:2,//是否切换班次:2:否,1:是
            //     approvalFormId:'',//审批单id
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    console.log(this.clockinginRuleForm, '考勤设置提交');
                    if (this.clockinginRuleForm.isShiftChange === 2) {
                        this.clockinginRuleForm.approvalFormId = ''
                    }
                    let list = [];
                    list[0] = {
                        id: this.clockinginRuleForm.ids[0] || '',
                        schoolId: this.schoolId,
                        startTime: this.clockinginRuleForm.collateTime[0],
                        endTime: this.clockinginRuleForm.collateTime[1],
                        timeType: 1,
                    }
                    list[1] = {
                        id: this.clockinginRuleForm.ids[1] || '',
                        schoolId: this.schoolId,
                        timeType: 3,
                        isShiftChange: this.clockinginRuleForm.isShiftChange,
                        approvalFormId: this.clockinginRuleForm.approvalFormId,
                    }
                    list[2] = {
                        id: this.clockinginRuleForm.ids[2] || '',
                        schoolId: this.schoolId,
                        startTime: this.clockinginRuleForm.inspectTime[0],
                        endTime: this.clockinginRuleForm.inspectTime[1],
                        timeType: 2,
                        shiftName: this.clockinginRuleForm.shiftName,
                    }
                    if (this.clockinginRuleForm.classesType === 2) {
                        list[3] = {
                            id: this.clockinginRuleForm.ids[3] || '',
                            schoolId: this.schoolId,
                            startTime: this.clockinginRuleForm.inspectTime1[0],
                            endTime: this.clockinginRuleForm.inspectTime1[1],
                            timeType: 2,
                            shiftName: this.clockinginRuleForm.shiftName1,
                        }
                    }
                    console.log(list, '考勤设置提交的数据');
                    const campusSafetyDormitoryModel = new CampusSafetyDormitoryModel()
                    campusSafetyDormitoryModel.CampusSafetySaveSetting(list).then((res) => {
                        if (res.data.code === "200") {
                            this.$message.success("操作成功");
                            this.handleClockinginClose();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    });
                } else {
                    return false;
                }
            });
            console.log(this.clockinginRuleForm);
        },
        getFirstAndLastDay(date = new Date()) {
            const stringDate = new Date(date);
            const firstDate = new Date(
                stringDate.getFullYear(),
                stringDate.getMonth(),
                1,
            );
            let lastDate = new Date(
                stringDate.getFullYear(),
                stringDate.getMonth() + 1,
                0,
            );
            return [
                formatTimeObj(firstDate, "y-m-d"),
                formatTimeObj(lastDate, "y-m-d"),
            ];
        },
        routerType() {
            this.savePageInfo();
            this.$emit("changeType", 2);
        },
        getFirstThreeNodes(tree) {
            let result = []
            tree.forEach((item) => {
                item?.roList.forEach((it) => {
                    result.push(it)
                })
            })
            return result.slice(0, 3)
        },
    },
    watch: {
        groupSearchTime(val) {
            if (val && val.length === 2) {
                this.startTime = val[0];
                this.endTime = val[1];
            } else {
                this.startTime = "";
                this.endTime = "";
            }
        },
        type(val) {
            this.keyValue++;
            this.list = [];
            this.moveIndex = {
                rowIndex: "",
                columnIndex: [],
            };
            if (val === 1) {
                // 保存当前选中宿舍
                this.roomCascaderValue = this.cascaderValue;
                // 获取之前选中教师数据
                this.cascaderValue = this.teacherCascaderValue;
                this.cascaderOptions = this.teacherList;
                this.dialogCascaderOptions = this.roomList;
            } else {
                // 保存当前选中老师
                this.teacherCascaderValue = this.cascaderValue;
                // 获取之前选中宿舍数据
                this.cascaderValue = this.roomCascaderValue;
                this.cascaderOptions = this.roomList;
                this.dialogCascaderOptions = this.teacherList;
            }
            this.getList();
        },
    },
};
</script>
<style lang="scss">
.el-popover.popover-wrapper-hxh {
    padding: 0;
    max-height: 300px;
    overflow: auto;

    .popper__arrow {
        display: none;
    }
}
</style>
<style scoped lang="scss">
.attendanceSetting_form {
    // padding: 0 10px;

    .tip {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        margin-left: 12px;
    }

    .el-form-item--small.el-form-item {
        margin-bottom: 0;
        margin-left: 12px;
        display: flex;
        align-items: center;
    }

    .el-form-item__label {
        width: 100px;
    }

    .attendanceSetting {
        display: flex;
        align-items: center;

        .line {
            width: 3px;
            height: 14px;
            background: #2F95F5;
            border-radius: 2px;
        }

        .title {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            margin: 0 20px 0 8px
        }

        .tip {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
        }
    }
}

.content-wrapper {
    margin-right: 10px;
    border-radius: 4px;
    box-sizing: content-box;

    .form-wrapp {
        border-radius: 4px;
        margin-bottom: 10px;
        padding: 10px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:after {
            content: "";
            display: table;
            clear: both;
        }

        button>img {
            margin-right: 6px;
            vertical-align: middle;
        }

        .spanSty>img {
            height: 14px;
            margin-right: 6px;
            vertical-align: middle;
        }

        .el-button .spanSty {
            display: flex;
            text-align: center;
            align-items: center;
        }

        ::v-deep {
            .el-input__inner {
                background: #f5f5f5;
                border: transparent 1px solid;
            }

            .el-range-editor .el-range-input {
                background: #f5f5f5;
            }

            .el-input__inner:hover {
                border: #d4d6d9 1px solid;
            }
        }
    }

    .table-wrap {
        border-radius: 6px;
        overflow: auto;
        background-color: #fff;
        height: calc(100vh - 200px);

        .calendar-table {
            border-collapse: collapse;

            th {
                border: solid #e9e9e9;
                border-width: 0 1px 1px 0;
                box-sizing: border-box;
                padding: 5px 30px;
                height: 68px;
                background-color: #fff;
                position: sticky;
                top: 0;

                &:first-child {
                    left: 0;
                    min-width: 280px;
                    z-index: 2;
                }

                .header-item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 40px;
                    height: 56px;
                    border: 1px solid #e6e6e6;
                    border-radius: 8px;
                    color: #333333;
                    box-sizing: border-box;
                    font-weight: normal;
                }
            }

            td {
                text-align: center;
                padding: 0;
                box-sizing: border-box;

                &:first-child {
                    position: sticky;
                    left: 0;
                    border: solid #e9e9e9;
                    border-width: 0 1px 1px 0;
                    background-color: #fff;
                }

                &:nth-child(n + 2) {
                    border: dashed #e9e9e9;
                    border-width: 0 1px 1px 0;
                    cursor: pointer;
                    background-color: #fafafa;
                }
            }

            .is-range {
                background-color: #e4ebf2 !important;
            }
        }
    }
}

.popover-slot {
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    padding: 8px 0;
    box-sizing: border-box;

    .table-room-name {
        display: flex;
        align-items: center;
        flex: 1;

        .room-name-text {
            width: 76px;
            padding: 2px 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.change-img {
    margin-left: 10px;
    cursor: pointer;
}

.room-name {
    font-size: 12px;
    color: #333333;
}

.popover-room-name {
    padding: 10px 0;
    // text-align: center;
}

//el-cascader 多选下拉显示异常的问题
/deep/ .el-dialog__body {
    overflow-x: hidden;
}

::v-deep{
    .el-loading-spinner{
       
    }
}

</style>
